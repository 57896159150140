import { Injectable } from '@angular/core';
import { NgControl } from '@angular/forms';
import { ValidationMessageService } from '@bc-libs/core';
import { startCase } from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {
  constructor(
    private messageService: ValidationMessageService,
  ) { }

  getMessage(control: NgControl, messages: Record<string, string>, controlName: string): string {
    if (!control.errors) {
      return;
    }

    const friendlyControlName = controlName || startCase(control.name).toLowerCase();
    const key = Object.keys(control.errors)[ 0 ];
    const error = control.getError(key);

    return messages[ key ] ?? this.messageService.getMessage(key, friendlyControlName, error);
  }
}
