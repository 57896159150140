import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessageService } from './error-message.service';

@Component({
  selector: 'bcc-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: [ './form-field-error.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldErrorComponent implements OnInit, OnDestroy {
  @Input() messages: Record<string, string> = {};
  @Input() controlName;
  @Input() message: string;
  control: NgControl;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    @Optional() private controlContainer: MatFormField,
    private messageService: ErrorMessageService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.control = this.controlContainer?._control?.ngControl as NgControl;

    if (!this.control) {
      return;
    }

    merge(this.control.valueChanges, this.control.statusChanges)
      .pipe(
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.setMessage());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setMessage(): void {
    if (!this.control) {
      return;
    }

    this.message = this.messageService.getMessage(this.control, this.messages, this.controlName);
    this.cdr.markForCheck();
  }
}
