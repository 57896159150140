import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf
} from '@angular/core';
import { FormGroupDirective, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'us2-form-errors',
  standalone: true,
  imports: [],
  templateUrl: './form-errors.component.html',
  styleUrls: [ './form-errors.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormErrorsComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  showFormError: boolean;

  private readonly destroyed$ = new Subject<null>();

  constructor(
    @Optional() @SkipSelf() private formDirective: FormGroupDirective,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.setForm();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setForm(): void {
    if (!this.formDirective) {
      return;
    }

    this.form = this.formDirective.form;
    
    this.form.statusChanges
      .pipe(
        tap(_ => this.showFormError = Object.keys(this.form.controls).every(key => this.form.get(key).disabled ? true : this.form.get(key).valid)),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => this.cdr.markForCheck());
  }
}
